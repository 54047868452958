import {Col, Container, Row} from "react-bootstrap";
import MainImage from '../../images/historia/historia-main.jpg';
import MainHeader from "./../UI/MainHeader";

const AvisoDePrivacidad = () => {
    return <div>
        <MainHeader image={MainImage} activeButton={true} header={<h4 className={"mb-3 mt-md-5"}>Aviso de Privacidad</h4>}/>
        <Container className={"py-3 py-md-5"}>
            <Row>
                <Col xs={12}>
                    <p>En apego a la Ley Federal de protección de datos personales Agencia Aduanal Rodriguez y Cía., SC, y
                        Transportes R&amp;R S de RL de CV, declara ser una empresa legalmente constituida de conformidad con las leyes
                        mexicanas, con domicilio en Boulevard Tercera Oeste #17500 C Fraccionamiento Garita de Otay CP 22430 y
                        como responsable del tratamiento de sus datos personales, hace de su conocimiento que la información de
                        nuestros clientes es tratada de forma estrictamente confidencial por lo que al proporcionar sus datos personales
                        tales como:</p>
                    <ul>
                        <li>Nombre completo</li>
                        <li>Dirección</li>
                        <li>RFC</li>
                        <li>Teléfono</li>
                        <li>Correo</li>
                    </ul>
                    <p>
                        Estos serán utilizados única y exclusivamente para los siguientes fines:
                    </p>
                    <ul>
                        <li>Despacho Aduanero de acuerdo a la normatividad aplicable.</li>
                        <li>Actualización de base de datos</li>
                        <li>Información y prestación de servicios</li>
                    </ul>
                    <p>
                        En el caso de datos sensibles tales como:
                    </p>
                    <ul>
                        <li>Datos financieros</li>
                        <li>Datos personales</li>
                    </ul>
                    <p>
                        Estos serán utilizados para:
                    </p>
                    <ul>
                        <li>Despacho Aduanero de acuerdo a la normatividad aplicable, así como para llevar a cabo todos los
                            servicios que fueron solicitados por usted a nuestra empresa.</li>
                    </ul>
                    <p>
                        Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información utilizada para fines establecidos en este aviso de privacidad, hemos establecido diversos procedimientos con la finalidad de evitar el uso o divulgación no autorizada de sus datos, permitiéndonos tratarlos debidamente.
                        Asimismo, le informamos que sus datos personales pueden ser transmitidos para ser tratados por personas
                        distintas a esta empresa como es la Secretaría de Hacienda y Crédito Público, así como diversas dependencias
                        gubernamentales.

                    </p>
                    <p>
                        Todos sus datos personales son tratados de acuerdo a la legislación aplicable y vigente, por ello le informamos que usted en todo momento puede acceder, rectificar, cancelar u oponerse al tratamiento de le damos a sus datos personales (derechos ARCO), derecho que podrá hacer valer a través de nuestro departamento de clientes o por correo electrónico <span id="cloakcfbd2f5bd8b9fbf935c99d336e536565"><a href="mailto:servicios@aar.com.mx">servicios@aar.com.mx</a></span>.
                    </p>
                    <p>
                        A través de estos contactos usted podrá actualizar sus datos y especificar el medio por el cual desea
                        información ya que, en caso de no contar con esa especificación de su parte, Agencia Aduanal Rodriguez y
                        Cía., SC y Transportes R&amp;R S DE RL DE CV, establecerá libremente la forma de enviarle la información.
                        Este aviso de privacidad puede ser modificado por Agencia Aduanal Rodriguez y Cía., SC, y Transportes R&amp;R S
                        de RL de CV, dichas modificaciones serán oportunamente informadas.
                    </p>

                    <p>
                        Atentamente<br/>
                        - Agencia Aduanal Rodriguez y Cía., SC<br/>
                        - Transportes R&amp;R S de RL de CV
                    </p>
                </Col>
            </Row>
        </Container>
    </div>
}

export default AvisoDePrivacidad;