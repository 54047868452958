import * as yup from 'yup';

export const AgencyQuoteSchema = yup.object().shape({
    firstname: yup.string().required("El Nombre es requerido"),
    lastname: yup.string().required("El Apellido es requerido"),
    company: yup.string().required("La Empresa es requerida"),
    position: yup.string().required("El Puesto es requerido"),
    phone_office: yup.number("El Teléfono de Oficina es inválido").required("El Teléfono de Oficina es requerido"),
    phone_ext: yup.number("La Extensión es inválida"),
    phone_personal: yup.number("El Teléfono Particular es inválido"),
    comments: yup.string().required("El Comentario es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido"),
    merchandise: yup.string().required("El Nombre es requerido"),
    packaging: yup.string().required("El Apellido es requerido"),
    weight: yup.string().required("La Empresa es requerida"),
    operation: yup.string().required("La Empresa es requerida"),
    company_type: yup.string().required("La Empresa es requerida"),
    importer: yup.string().required("La Empresa es requerida"),
    importer_merchandise: yup.string().required("La Empresa es requerida"),
    shipments: yup.string().required("La Empresa es requerida"),
    shipments_parts: yup.string().required("shipments_parts")
});

export const AgencyQuoteMoreThanTenSchema = yup.object().shape({
    firstname: yup.string().required("El Nombre es requerido"),
    lastname: yup.string().required("El Apellido es requerido"),
    company: yup.string().required("La Empresa es requerida"),
    position: yup.string().required("El Puesto es requerido"),
    phone_office: yup.number("El Teléfono de Oficina es inválido").required("El Teléfono de Oficina es requerido"),
    phone_ext: yup.number("La Extensión es inválida"),
    phone_personal: yup.number("El Teléfono Particular es inválido"),
    comments: yup.string().required("El Comentario es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido")
});
