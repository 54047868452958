import {Col, Container, Row} from "react-bootstrap";
import "./Sucursales.scss";
import mapa from "./../../../images/grupo/mapa.png";

const Sucursales = () => {
    return <div id={"sucursales"}>
        <Container>
            <Row className={"d-flex flex-row"}>
                <Col xs={5} md={4} className={"py-3 p-lg-2 p-lg-5"}>
                    <div className={"py-2 py-xl-5"}>
                        <h2><span className={"d-block text-small"}>Sucursales en: </span>
                            <span className={"d-block text-yellow"}>Tijuana, Ensenada </span>
                            <span className={"d-block text-yellow"}>y San Diego </span></h2>
                        <p><span className={"d-block"}>Con presencia en:</span>
                            Mexicali, Manzanillo y Long Beach</p>
                    </div>
                </Col>
                <Col className={"position-relative right-map"}>
                    <img src={mapa} className={"img-fluid position-absolute"}/>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Sucursales;