import "./Timeline.scss";
import Logo1940 from '../../../images/historia/logo-1940.png';
import Logo1985 from '../../../images/historia/logo-1985.png';
import Logo2002 from '../../../images/historia/logo-2002.png';
import Logo1960 from '../../../images/historia/logo-1960.png';
import Logo1994 from '../../../images/historia/logo-1994.png';
import Logo2020 from '../../../images/historia/logo-2020.png';

const Timeline = () => {
    return (<section id="conference-timeline">
        <div className="timeline-start text-center">
            <h2 className={"d-inline-block d-md-block"}>Historia</h2>
            <p className={"d-inline-block d-md-block"}><span className={"d-inline-block d-md-none"}>&nbsp;/&nbsp;</span>Línea del tiempo</p>
            <div className="meta-date"/>
        </div>
        <div className="conference-center-line"/>

        <div className="conference-timeline-content">
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left">
                        <div
                            className="article-number">1940</div>
                        <p><span className="d-block">Se obtiene la primer patente </span>
                            <span className="d-block">No. 95 de agente aduanal de </span>
                            Grupo Logístico Rodríguez</p>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right timeline-logo text-left">
                        <img src={Logo1940} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left  timeline-logo text-right">
                        <img src={Logo1960} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right">
                        <div
                            className="article-number">1960</div>
                        <p><span className="d-block">Se establece la aduana y abren </span>
                            <span className="d-block">oficinas en el puerto de San </span>
                            <span className="d-block">Ysidro en la garita conocida </span>
                            actualmente como El Chaparral</p>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left">
                        <div
                            className="article-number">1985</div>
                        <p><span className="d-block">En 1985 se instalan en Estados </span>
                            Unidos para entrega de documentos.</p>
                        <p><span className="d-block">En 1991 nace R&R International </span>
                            <span className="d-block">Freight Forwarding para atender las </span>
                            <span className="d-block">necesidades de servicios de </span>
                            <span className="d-block">almacenamiento, consolidación y </span>
                            reexpedición de mercancías.</p>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right timeline-logo text-left">
                        <img src={Logo1985} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left  timeline-logo text-right">
                        <img src={Logo1994} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right">
                        <div
                            className="article-number">1994</div>
                        <p><span className="d-block">Con la transición de la Aduana a la</span>
                            <span className="d-block">Mesa de Otay se consolida la nueva</span>
                            <span className="d-block">ubicación de nuestras oficinas en</span>
                            Garita de Otay.</p>
                        <p><span className="d-block">En 1999 nace R&R International</span>
                            <span className="d-block">Transport para atender las</span>
                            <span className="d-block">necesidades de transporte con</span>
                            <span className="d-block">cobertura en las principales</span>
                            aduanas de la región.</p>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article">
                <div className="content-left-container">
                    <div className="content-left">
                        <div
                            className="article-number">2002</div>
                        <p><span className="d-block">Era digital y modernización </span>
                            del sistema aduanero.</p>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right  timeline-logo text-left">
                        <img src={Logo2002} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
            <div className="timeline-article pb-0 mb-0">
                <div className="content-left-container">
                    <div className="content-left  timeline-logo text-right">
                        <img src={Logo2020} className={"img-fluid"}/>
                    </div>
                </div>
                <div className="content-right-container">
                    <div className="content-right">
                        <div
                            className="article-number">2020</div>
                        <p><span className="d-block">Después de casi 20 años, se reinventa </span>
                            <span className="d-block">nuestra identidad corporativa conformado </span>
                            por las 3 unidades de negocio.</p>
                    </div>
                </div>
                <div className="meta-date"/>
            </div>
        </div>
    </section>);
}

export default Timeline;