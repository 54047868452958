import {Col, Container, Row} from "react-bootstrap";
import HeaderAcceso from "../../UI/header/HeaderAcceso";
import "./../Acceso.scss";
import freightImage from "./../../../images/acceso/portal-freight-forwarding.png";
import agenciaImage from "./../../../images/acceso/portal-agencia.png";
import transporteImage from "./../../../images/acceso/portal-transportes.png";
import Footer from "../../UI/Footer";

const AccesoDesktop = () => {
    return <div className={"acceso"}>
        <HeaderAcceso/>
        <Container>
            <Row>
                <Col lg={10} className={"mx-auto acceso-over pt-4"}>
                    <Row className={"align-items-center justify-content-between"}>
                        <Col className={"img-zoom"}>
                            <div className="img-container">
                                <a href={"#transporte"} target={"_blank"}>
                                    <img src={transporteImage} className={"img-fluid mb-3"}/>
                                    <button className={"btn btn-access"}><span className={"d-block"}>Acceso al </span> portal
                                    </button>
                                </a>
                            </div>
                        </Col>
                        <Col className={"d-none d-md-block divisor-vertical-access"}></Col>
                        <Col className={"img-zoom"}>
                            <div className="img-container">
                                <a href={"#agencia"} target={"_blank"}>
                                    <img src={agenciaImage} className={"img-fluid mb-3"}/>
                                    <button className={"btn btn-access"}><span className={"d-block"}>Acceso al </span> portal
                                    </button>
                                </a>
                            </div>
                        </Col>
                        <Col className={"d-none d-md-block divisor-vertical-access"}></Col>
                        <Col className={"img-zoom"}>
                            <div className="img-container">
                                <a href={"#frieght"} target={"_blank"}>
                                    <img src={freightImage} className={"img-fluid mb-3"}/>
                                    <button className={"btn btn-access"}><span className={"d-block"}>Acceso al </span> portal
                                    </button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className={"mb-4 mx-lg-2 text-right"}>
                    Acceso total al portal digital es válido para versión digital y versión escritorio.
                </Col>
            </Row>
        </Container>
        <Footer/>
    </div>
}

export default AccesoDesktop;