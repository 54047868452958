import "./Socios.scss";
import {Col, Container, Row} from "react-bootstrap";
import {useRef, useEffect, useState} from "react";
import SociosCard from "./SociosCard";

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const Socios = () => {
    const clientRef = useRef();
    const [height, setHeight] = useState(100);

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setHeight(clientRef.current.offsetWidth - 10 );
        }, 800)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    });

    useEffect(() =>{
        setHeight(clientRef.current.offsetWidth - 10 );
    },[clientRef]);

    const list = ["tcl", "calimax", "driscolls", "navico", "envases", "andrew-williamson", "parpro", "tcl", "calimax"];

    return <div className={"bg-light-blue"}>
        <Container className={"p-4 py-md-5"}>
            <Row className={"pb-3 pb-md-4"}>
                <Col>
                    <h2>
                        Algunos socios comerciales que confían en nuestro servicio.
                    </h2>
                    <p>Nuestras experiencias y calidad de servicio nos permiten brindar apoyo a empresas de distintas industrias, enfocándonos en mejorar la eficiencia en su cadena de suministro</p>
                </Col>
            </Row>
            <Row>
                <Col xs={11} md={10} className={"mx-auto"}>
                    <div id={"listado-clientes"}>
                        <div className="grid">
                            {
                                list.map((name) => {
                                    return <div ref={clientRef} style={{ height: height }}>
                                        <SociosCard name={name}/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Socios;