import {createTheme} from '@mui/material';

const theme = createTheme({
    palette: {
        success: {
            50: "#daf5e6",
            100: "#b5ebce",
            200: "#91e1b6",
            300: "#6cd79e",
            400: "#6cd79e",
            500: "#30b76f",
            600: "#279258",
            700: "#2c9f62",
            800: "#258451",
            900: "#1d6a41",
            A100: "#bcecd2",
            A200: "#7ad9a6",
            A400: "#38c67a",
            A700: "#258451",
            contrastText: "#fff",
            dark: "#248752",
            light: "#4EBA7D",
            main: "#359c64"
        },
        primary: {
            50: "#fffed8",
            100: "#fdfac7",
            200: "#fcf7b6",
            300: "#fcf3a5",
            400: "#fcee93",
            500: "#fcea81",
            600: "#fde56f",
            700: "#fee05b",
            800: "#ffdb46",
            900: "#fcb910",
            A100: "#f8df93",
            A200: "#fcdd77",
            A400: "#fedc58",
            A700: "#fcb910",
            main: "#fcbf24",
            light: "#fccb4c",
            dark: "#916903",
            contrastText: "#000"
        },
    },

    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: '#212529',
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.Mui-select': {
                        '& .MuiFormLabel-filled': {
                            top: '1rem'
                        },
                        '& label.Mui-focused': {
                            color: '#212529',
                            top: '1rem'
                        },
                    },
                    expanded: {
                        '&$expanded': {
                            margin: '4px 0'
                        }
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#db3131",
                    "&$error": {
                        color: "#db3131",
                    },
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                root: {
                    '&': {
                        width: '100%',
                        textAlign: 'left'
                    }
                }
            }
        },

        MuiInput: {
            styleOverrides: {
                root: {
                    '&': {
                        width: '100%',
                        textAlign: 'left'
                    }
                }
            }
        }
    },
});

export default theme;