import {Col, Container, Row} from "react-bootstrap";
import Contador from "./Contador";
import "./Almacen.scss";
import {IsViewportVisible} from "./../../../hooks/IsViewportVisible";
import {useRef, useState, useEffect} from "react";

const Almacen = () => {
    return <div id={"almacen"}>
        <Container className={"py-2 py-md-5"}>
            <Row className={"d-flex flex-row-reverse"}>
                <Col xs={8} md={6} lg={5} className={"py-3 px-4 p-md-2 p-lg-5"}>
                    <Row>
                        <Col>
                            <h2>Almacén</h2>
                            <p>Almacén localizado en San Diego, autorizado como Zona Libre de Comercio (FTZ / Inbond), para distribución y liberación de embarques marítimos.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Contador duration={3} number={"40000"} description={"SqFt en Almacén"} />
                        </Col>
                        <Col>
                            <Contador duration={3} number={"60"} description={"Usuarios activos"}/>
                        </Col>
                        <Col>
                            <Contador duration={3} number={"20"} description={"Andenes"}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Almacen;