import "./ContactStrip.scss";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {getExchange} from "../../utils/exchange";

const ContactStrip =({exchange}) =>{
    const [exchangePrice, setExchangePrice] = useState({
        buy: 0,
        sell: 0,
        updated_date: (new Date().toLocaleDateString("es-ES", { month: 'long', day: 'numeric' }))
    });

    useEffect(() => {
        if(exchange){
            getExchange().then((data) => {
                setExchangePrice(data);
            });
        }
    } ,[])

    return <div className={"contact-strip"}>
        <Container>
            {exchange ? <Row className={"pt-2 pt-md-3"}>
                <Col xs={12} xxl={11} className={"mx-auto"}>
                    <Row className={"exchange  text-center"}>
                        <Col lg={2} xl={3} className={"mb-3 mb-lg-0 label"}>Tipo de cambio</Col>
                        <Col xs={12} lg>
                            <Row className={"d-flex flex-row-reverse flex-md-row"}>
                                <Col xs>
                                    <span className={"price"}>
                                        ${exchangePrice.buy}
                                    </span>
                                </Col>
                                <Col xs={7}>
                                    <span className={"date d-block w-100"}>{exchangePrice.updated_date}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs className={"d-none d-xl-block divisor-diagonal"}/>
                        <Col xs={12} lg className={"mt-2 mt-lg-0"}>
                            <Row className={"d-flex flex-row-reverse flex-md-row"}>
                                <Col xs>
                                    <span className={"price"}>
                                        ${exchangePrice.sell}
                                    </span>
                                </Col>
                                <Col xs={7}>
                                    <span className={"date d-block w-100"}>{exchangePrice.updated_date}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row> : null}
            <Row className={"py-2 py-md-4 px-md-0"}>
                <Col xs={12} className={"mx-auto px-md-0"}>
                    <Row>
                        <Col xs={12} sm={6} lg className={"mt-2 mt-lg-0 icon at-email"}>
                            <div className={"text-md-center"}>
                                <strong className={"d-block"}>Cotíza vía E-mail</strong>
                                <a href={"mailto:negocios@aar.com.mx"}>negocios@aar.com.mx</a>
                            </div>
                        </Col>
                        <Col xs className={"d-none d-lg-block divisor-diagonal"}/>

                        <Col xs={12} sm={6} lg className={"my-3 my-lg-0 icon phone-call"}>
                            <div className={"text-md-center"}>
                            <strong className={"d-block"}>Llámanos | Cotizaciones</strong>
                            <a href={"tel:526646831022"}>+52 (664) 683 1022</a>
                            </div>
                        </Col>
                        <Col xs className={"d-none d-lg-block divisor-diagonal"}/>
                        <Col xs={12} sm={6} lg className={"mb-2 mb-lg-0 icon home-location mx-auto max-width"}>
                            <div className={"text-md-center"}>
                            <strong className={"d-block"}>Horario- Sucursales</strong>
                            Lunes - Viernes&nbsp;&nbsp; 8 am - 5:30 pm
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>;
}

export default ContactStrip;