import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom";

import Nosotros from "./components/pages/Nosotros";
import Inicio from "./components/pages/Inicio";
import Acceso from "./components/pages/Acceso";
import AvisoDePrivacidad from "./components/pages/AvisoDePrivacidad";
import Servicios from "./components/pages/Servicios";
import Grupo from "./components/pages/grupo/Grupo";
import Historia from "./components/pages/historia/Historia";
import Clientes from "./components/pages/clientes/Clientes";
import Contacto from "./components/pages/Contacto";
import Noticia from "./components/pages/noticias/VerNoticia";

import AgenciaCotizaciones from "./components/pages/agencia/AgenciaCotizaciones"
import TransporteCotizaciones from "./components/pages/transporte/TransporteCotizaciones"
import FreightCotizaciones from "./components/pages/freight/FreightCotizaciones"

import NotFound from "./components/pages/NotFound";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import './i18n';
import {useEffect, useState} from "react";
import {UseViewPort} from "./hooks/useViewPort";
import Agencia from "./components/pages/agencia/Agencia";

function App() {
    const isMobile = UseViewPort() < 992;

    const [showFooter, setShowFooter] = useState(true);

    useEffect( () => {
        setShowFooter(false);
    },[isMobile]);

    return (
        <Router>
            <Switch>
                <Route exact path={`/`} component={Inicio}/>
                <Route exact path={`/servicios`} component={Servicios}/>
                <Route exact path={`/nosotros`} component={Nosotros}/>
                <Route exact path={`/historia`} component={Historia}/>
                <Route exact path={`/grupo`} component={Grupo}/>
                <Route exact path={`/clientes`} component={Clientes}/>
                <Route exact path={`/contacto`} component={Contacto}/>
                <Route exact path={`/acceso`} component={Acceso}/>
                <Route exact path={`/noticias/:id`} component={Noticia}/>
                {/*<Route exact path={`/agencia`} component={Agencia}/>*/}
                <Route exact path={`/agencia/cotizacion`} component={AgenciaCotizaciones}/>
                <Route exact path={`/international-transport/cotizacion`} component={TransporteCotizaciones}/>
                <Route exact path={`/freight-forwarding/cotizacion`} component={FreightCotizaciones}/>

                <Route exact path={`/aviso-de-privacidad`} component={AvisoDePrivacidad}/>

                { showFooter && <Route component={NotFound}/>  }
            </Switch>
        </Router>
    );
}

export default App;
