import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText,
    Radio, RadioGroup, FormLabel
} from "@mui/material";
import React from "react";
import {Alert} from "react-bootstrap";

const CustomForm = ({fieldsValues, fields, setFieldsValues, setErrors, errors, sameRowSize = false, formError="", setFormError}) => {

    const handleChange = (event) => {
        setFieldsValues({
            ...fieldsValues, [event.target.name]: event.target.value
        });
    }

    const handleFocus = (event) => {
        setErrors({
            ...errors, [event.target.name]: ''
        });

        setFormError("");
    }

    return <React.Fragment>
        {fields.map((field) => {
            switch (field.type) {
                case "checkbox":
                    return (<Grid item xs={sameRowSize ? field.rowSize : 12} md={field.rowSize} align={"left"} key={`contact-grid-${field.name}`}>
                        <FormControlLabel key={`contact-field-${field.name}`} control={<Checkbox/>} label={field.placeholder} sx={{
                            "& .MuiSvgIcon-root": {
                                fontSize: "2rem",
                            },
                        }}/>
                    </Grid>);
                case "radio":
                    return (<Grid item xs={sameRowSize ? field.rowSize : 12} md={field.rowSize} align={"left"} key={`contact-grid-${field.name}`}>
                        <FormControl>
                            <FormLabel id="demo-form-control-label-placement">{field.placeholder}</FormLabel>
                            <RadioGroup
                                key={`contact-field-${field.name}`}
                                row
                                aria-labelledby={`field-name-${field.name}`}
                                name="position"
                                defaultValue="top"
                            >


                                {field.options.map((option) => (<FormControlLabel
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                    labelPlacement="start"
                                />))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>);
                case "textarea":
                    return (<Grid item xs={sameRowSize ? field.rowSize : 12} md={field.rowSize} key={`contact-grid-${field.name}`}>
                        <TextField
                            key={`contact-field-${field.name}`}
                            multiline
                            rows={4}
                            fullWidth
                            id={`contact-${field.name}`}
                            label={field.placeholder}
                            name={field.name}
                            value={ fieldsValues.hasOwnProperty(field.name) ? fieldsValues[field.name] : '' }
                            required={field.required}
                            onChange={handleChange} onFocus={handleFocus}
                            {...(errors[field.name] && { error: true, helperText: errors[field.name]  })}
                            variant="filled"
                        />
                    </Grid>);
                case "select":
                    return (<Grid item xs={sameRowSize ? field.rowSize : 12} md={field.rowSize} key={`contact-grid-${field.name}`}>
                        <FormControl key={`contact-label-${field.name}`} className={`Mui-select ${field.required ? "Mui-select-required" : null}`}
                                     fullWidth {...(errors[field.name] && { error: true })}>
                            <InputLabel key={`contact-label-${field.name}`} id={`contact-${field.name}-label`}>{`${field.placeholder}`}</InputLabel>
                            <Select
                                key={`contact-field-${field.name}`}
                                inputProps={{
                                    MenuProps: {
                                        disableScrollLock: true,
                                    },
                                }}
                                labelId={`contact-${field.name}-label`}
                                id={`contact-${field.name}`}
                                name={field.name}
                                value={ fieldsValues.hasOwnProperty(field.name) ? fieldsValues[field.name] : '' }
                                label={`${field.placeholder} ${field.required ? "*" : null}`}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleFocus(e);
                                }}
                                variant="filled"
                                required={field.required}>
                                <MenuItem value="">
                                    <em>Ninguno</em>
                                </MenuItem>
                                {field.options.map((option) => (<MenuItem key={option} value={option}> {option} </MenuItem>))}
                            </Select>
                            {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText> }

                        </FormControl>
                    </Grid>);
                default:
                    return (<Grid item xs={sameRowSize ? field.rowSize : 12} md={field.rowSize} key={`contact-grid-${field.name}`}>
                        <TextField
                            key={`contact-field-${field.name}`}
                            fullWidth
                            id={`contact-${field.name}`}
                            label={field.placeholder}
                            name={field.name}
                            variant="filled"
                            required={field.required}
                            value={ fieldsValues.hasOwnProperty(field.name) ? fieldsValues[field.name] : '' }
                            {...(errors[field.name] && { error: true, helperText: errors[field.name]  })}
                            onChange={handleChange} onFocus={handleFocus}/>
                    </Grid>);
            }
        })}

        {formError.length > 0 && <Grid item xs={12}>
            <Alert key="contact-error" variant={"danger"} className={"mb-0 text-left"}>
                {formError}
            </Alert>
        </Grid>}
    </React.Fragment>;
}

export default CustomForm;