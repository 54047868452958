import {UseViewPort} from "./../../hooks/useViewPort";
import "./PluginChat.scss";

const PluginChat = () => {
    const isMobile = UseViewPort() < 992;

    return <div>
        {isMobile && <a className={"wa-mobile"} target="_blank" href={"https://api.whatsapp.com/send?phone=526644102854"}><span/></a>}
        {!isMobile && <div className="elfsight-app-dcb526ff-24b0-4771-a45c-e44342bff057"/>}
    </div>;
}

export default PluginChat;