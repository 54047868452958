import axios from "axios";

const formatExchange = (number) => {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2 });
}

const formatDate = (currentDate) => {
    return (new Date(currentDate).toLocaleDateString("es-ES", { month: 'long', day: 'numeric' }));
}

const getExchange = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/exchange`).then((response) => {
        let result = response.data[0];
        return {
            buy: formatExchange(result.buy),
            sell: formatExchange(result.sell),
            updated_date: formatDate(result.updated_at)
        }
    }).catch(() => {
        let defaultPrice = formatExchange(0);
        return {
            buy: defaultPrice,
            sell: defaultPrice,
            updated_date: formatDate('')
        }
    });
}


export {getExchange};