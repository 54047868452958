import {Col, Row} from "react-bootstrap";
import React from 'react';
import CertificacionUno from "./../../../images/inicio/certificaciones/ctpat-logo-active.png";
import CertificacionOEA from "./../../../images/inicio/certificaciones/oea-logo-active.png";
import CertificacionQCB from "./../../../images/inicio/certificaciones/qcb-logo-active.png";
import CertificacionUS from "./../../../images/inicio/certificaciones/us-customs-logo-active.png";

import "./Certificaciones.scss";

const Certificaciones = ({hideText}) => {
    return <React.Fragment>
        <div className={"certificaciones"}>
            { !hideText && <div><h2 className={"mt-md-3"}>Certificaciones</h2>
            <p className={"mt-md-3"}>Nuestras certificaciones nacionales e internacionales garantizan que todas ls
                operaaciones
                que realizamos cumplen
                con un procedimiento par alcanzar los mejores resultados de seguridad, control y
                coordinación en actividades de comercio
                exterior y aduanas.</p></div>}
            <Row className={`text-center ${hideText ? 'mt-5 mt-md-2' : 'mt-md-4' }`}>
                <Col className={"cert-item"} xs={hideText ? 6 : 12}  md>
                    <img src={CertificacionOEA} className={'img-fluid'}/>
                </Col>
                <Col className={"cert-item"} xs={hideText ? 6 : 12} md>
                    <img src={CertificacionUno} className={'img-fluid'}/>
                </Col>
                <Col className={"cert-item"} xs={hideText ? 6 : 12} md>
                    <img src={CertificacionUS} className={'img-fluid'}/>
                </Col>
                <Col className={"cert-item"} xs={hideText ? 6 : 12} md={2}>
                    <img src={CertificacionQCB} className={'img-fluid'}/>
                </Col>
            </Row>
        </div>
    </React.Fragment>
}

export default Certificaciones;