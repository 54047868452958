import {Col, Container, Row, Form} from "react-bootstrap";
import "./Inicio.scss";

import MainImage from '../../images/inicio/home-main.png';
import Certificaciones from "./inicio/Certificaciones";

import MainHeader from "../UI/MainHeader";
import PluginChat from "../UI/PluginChat";
import NoticiasCarousel from "./inicio/NoticiasCarousel";
import React  from "react";
import CotizaAhora from "./inicio/CotizaAhora";
import CotizaAhoraMobile from "./inicio/CotizaAhoraMobile";

import TusSocios from "./inicio/TusSocios";
import {UseViewPort} from "../../hooks/useViewPort";
import Footer from "../UI/Footer";

const Inicio = () => {
    const isMobile = UseViewPort() < 992;
    const showNews = false;
    return <div>
        <MainHeader image={MainImage} exchange={true} type={"inicio"}/>
        <Container fluid>
            <Row>
                <Col xs={4} className={"service-block parent-hover p-3 p-md-5"} id={"service-one"}>
                    <h3><span className={"d-block"}>Agencia </span>
                        <span className={"d-block"}>Aduanal </span>Rodríguez</h3>
                    <a href={`${process.env.PUBLIC_URL}/servicios`} className={"btn-filled"}>Información</a>
                </Col>
                <Col xs={4} className={"service-block parent-hover p-3 p-md-5"} id={"service-two"}>
                    <h3>
                        <span className={"d-block"}>R&R </span>
                        <span className={"d-block"}>International </span>
                        Transport
                    </h3>
                    <a href={`${process.env.PUBLIC_URL}/servicios`} className={"btn-filled"}>Información</a>
                </Col>
                <Col xs={4} className={"service-block parent-hover p-3 p-md-5"} id={"service-three"}>
                    <h3>
                        <span className={"d-block"}>R&R </span>
                        <span className={"d-block"}>International </span>
                        <span className={"d-block"}>Freight </span>
                        Forwarding
                    </h3>
                    <a href={`${process.env.PUBLIC_URL}/servicios`} className={"btn-filled"}>Información</a>
                </Col>
            </Row>
        </Container>
        <Container fluid className={"main p-0"}>
            <div className={"cotiza-ahora py-md-4"}>
                { isMobile ? <CotizaAhoraMobile/> : <CotizaAhora/> }
            </div>
            <Container fluid className={"px-0 py-2 py-md-5 my-2 my-md-5"} style={{ position: 'relative', overflow: 'hidden'}}>
               <TusSocios isMobile={isMobile}/>
            </Container>
            <Container>
                <Row className={"pb-1 pb-md-2  mb-md-2"}>
                    <Col>
                        <h2>Información de Interés</h2>
                        <p>Te brindamos noticias, comunicados, avisos y circulares al día, para que estes bien informado.</p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={"news"}>
                <NoticiasCarousel/>
            </Container>
            <Container className={"py-3 py-md-5"}>
                <Row className={"pb-2 mb-4"}>
                    <Col className={"contact-box mx-auto"} md={11}>
                        <Row>
                            <Col xs={12} md className={"image d-inline-block"}/>
                            <Col xs={12} md className={"p-3 p-md-4 d-flex flex-column justify-content-between"}>
                                <div>
                                    <h3 className={"text-yellow"}>Atención personalizada</h3>
                                    <p>Tu opinión es importante para nosotros, escríbenos
                                        para atender tu solicitud.</p>
                                </div>
                                <div>
                                    <>
                                        <Form.Group className="mb-3">
                                            <Form.Control name={"name"} placeholder="Nombre" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control name={"phone"} placeholder="Teléfono" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control name={"email"} placeholder="Correo Electrónico" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control as="textarea" rows={3} placeholder="Mensaje" />
                                        </Form.Group>
                                    </>
                                    <button className={"btn-filled mb-0"}>Enviar</button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                </Row>
                <Row className={"mb-3 px-md-5 px-2"}>
                    <Col>
                        <Certificaciones/>
                    </Col>
                </Row>
            </Container>
            <PluginChat/>
        </Container>
        <Footer/>
    </div>
}

export default Inicio;