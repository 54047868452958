import {useEffect, useRef, useState} from "react";
import {contactSchema} from "../../../validations/ContactValidation";
import theme from "../../../theme";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Grid} from "@mui/material";
import CustomForm from "../../UI/CustomForm";
import {ThemeProvider} from "@emotion/react";
import {AgencyQuoteSchema} from "../../../validations/AgencyValidation";
import {postAgencia} from "../../../utils/forms";

const FreightCotizacionMenosDiez = ({setStatus}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errors, setErrors] = useState({});
    const [fieldsValues, setFieldsValues] = useState({});

    const fields = [
        {
            type: "text",
            name: "firstname",
            required: true,
            rowSize: 7,
            placeholder: "Nombre",
        },
        {
            type: "text",
            name: "lastname",
            required: true,
            rowSize: 5,
            placeholder: "Apellido",
        },
        {
            type: "text",
            name: "company",
            required: true,
            rowSize: 7,
            placeholder: "Empresa",
        },
        {
            type: "text",
            name: "position",
            required: true,
            rowSize: 5,
            placeholder: "Puesto",
        },
        {
            type: "email",
            name: "email",
            required: true,
            rowSize: 12,
            placeholder: "Correo Electrónico",
        },
        {
            type: "phone",
            name: "phone_office",
            required: true,
            rowSize: 5,
            placeholder: "Teléfono de Oficina",
        },
        {
            type: "number",
            name: "phone_ext",
            required: false,
            rowSize: 2,
            placeholder: "Extensión",
        },
        {
            type: "phone",
            name: "phone_personal",
            required: false,
            rowSize: 5,
            placeholder: "Teléfono Particular",
        },
        {
            type: "text",
            name: "mercancia",
            required: true,
            rowSize: 7,
            placeholder: "Que mercancía maneja?",
        },
        {
            type: "text",
            name: "embalaje",
            required: true,
            rowSize: 3,
            placeholder: "Embalaje",
        },
        {
            type: "text",
            name: "peso",
            required: true,
            rowSize: 2,
            placeholder: "Peso",
        },
        {
            type: "select",
            name: "tipo_operación",
            required: true,
            rowSize: 6,
            placeholder: "Tipo de operación requerida",
            validator: "Operación",
            options: ["Importación", "Exportación", "Importación y exportación"]
        },
        {
            type: "select",
            name: "tipo_empresa",
            required: true,
            rowSize: 6,
            placeholder: "Tipo de empresa",
            validator: "Tipo de empresa",
            options: ["Persona física", "Persona moral", "IMMEX"]
        },
        {
            type: "radio",
            name: "padron_importaciones",
            required: true,
            rowSize: 6,
            placeholder: "Cuenta con padrón de importaciones",
            validator: "Padrón de importanciones",
            options: ["Si", "No"]
        },
        {
            type: "select",
            name: "mercancia_maneja",
            required: true,
            rowSize: 6,
            placeholder: "Qué mercancia maneja?",
            validator: "mencancia maneja",
            options: ["Consulta / Comentarios", "Presupuesto", "Seguimiento"]
        },
        {
            type: "select",
            name: "cantidad_mensual",
            required: true,
            rowSize: 6,
            placeholder: "Cantidad de embarque mensuales:",
            validator: "embarque mensuales",
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "Proyecto único"]
        },
        {
            type: "select",
            name: "partes_por_embarque",
            required: true,
            rowSize: 6,
            placeholder: "Número de partes por embarque:",
            validator: "embarque mensuales",
            options: ["De 1 a 10", "De 10 a 20", "Más de 20"]
        },{
            type: "textarea",
            rows: 4,
            name: "comments",
            required: true,
            rowSize: 12,
            placeholder: "Comentario"
        }
    ];

    const validateData = async() => {
        try {
            await AgencyQuoteSchema.validate(fieldsValues, { abortEarly: false });
            return true;
        } catch (e) {
            let messages = e.errors;

            if(!messages.length) return true;
            let populateErrors = [];

            fields.map((field) => {
                const match = messages.find((element, index) => {
                    let containText = field.validator ?? field.placeholder;
                    if (element.includes(containText)) {
                        return messages[index];
                    }
                    return false;
                });

                if(typeof match === 'string'){
                    populateErrors[field.name] = match;
                }
                return true;
            });
            setErrors(populateErrors);
            return false;
        }
    }

    const sendForm = () => {
        setLoading(true);
        return validateData().then( async (response) => {
            if(response){
                fieldsValues.formname = 'Cotizacion Transporte';
                fieldsValues.formtitle = 'Menos de 10 movimientos';

                await postAgencia(fieldsValues).then((result) => {
                    setStatus(true);
                }).catch( () => {
                    setErrorMsg('Hubo un error, favor intente nuevamente.');
                }).finally(() => {
                    setLoading(false);
                })
            }else{
                setLoading(false);
            }

        });
    };

    return <ThemeProvider theme={theme}>
        <Container>
            <Row>
                <Col>
                    <h3 className={"font-weight-light"}>Mayor de <span className={"font-weight-bold"}>10 operaciones</span> al mes</h3>
                </Col>
            </Row>
            <Grid container spacing={2} align={"center"} >
                <CustomForm fieldsValues={fieldsValues} fields={fields}  setFieldsValues={setFieldsValues} errors={errors} setErrors={setErrors} sameRowSize={true} formError={errorMsg} setFormError={setErrorMsg}/>
                <Grid item xs={12} align={"left"}>
                    <Button key="contact-btn" className={"btn btn-enviar mb-4 mb-md-0 border-none"} onClick={sendForm}>
                        {loading ? 'Enviando...' : 'Enviar solicitud'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    </ThemeProvider>
}

export default FreightCotizacionMenosDiez;