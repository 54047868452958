import noticiaThumb from '../../../images/noticias/example-thumb.png';
import {Col, Container, Row, Form} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import {useEffect, useState} from "react";

const NoticiasCarousel =  () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/news`).then((response) => {
            setNews(response.data);
            setLoading(false);
        }).catch(() => {
            throw new Error("There was an error with api news");
        });
    },[]);

    let carouselResponsive = {
        0: {
            items: 1,
            loop: true,
            mouseDrag: true,
            touchDrag: true
        },

        768: {
            items: 2,
            loop: true,
            mouseDrag: true,
            touchDrag: true,
        },
        992: {
            items: 4,
            mouseDrag: true,
            touchDrag: true,
            loop: false
        },
    }

    return <Container>

        { !loading && <Row className={"g-0"}>
            <OwlCarousel className="owl-theme" margin={0} nav={true} mergeFit={true} dots={false}
                         responsive={carouselResponsive} lazyLoad={true} navText={['', '']}>
                {news.map( (item) => {
                    const dateFragments = item.publish_date.split('-');
                    const formattedDate = (new Date(dateFragments[0], dateFragments[1]-1, dateFragments[2])).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' });
                    return <div xs={12} sm={6} lg={"auto"} className={"preview p-md-0"} key={`noticia-${item.id}`}>
                        <h6 className={"p-1 p-md-3"}>Noticia</h6>
                        <img src={noticiaThumb} className={"img-fluid"}
                             alt={item.title}/>
                        <div className={"details  d-block p-1 p-md-3"}>
                            <h5>{item.title}</h5>
                            <div className={"bottom-box"}>
                                <div className={"publish-date"}>{formattedDate}</div>
                                <a className={"read-more"} target="_blank" href={`${process.env.PUBLIC_URL}/noticias/${item.id}`}>
                                    Leer nota completa
                                </a>
                            </div>
                        </div>
                    </div>
                })}
            </OwlCarousel>
        </Row>}
    </Container>;
}

export default NoticiasCarousel;