import AccesoDesktop from "./acceso/AccesoDesktop";
import AccesoMobil from "./acceso/AccesoMobil";

import {UseViewPort} from "../../hooks/useViewPort";
import logoTransporte from "../../images/acceso/logo-transporte.png";
import logoAgencia from "../../images/acceso/logo-agencia.png";
import logoFreight from "../../images/acceso/logo-freight.png";

const Acceso = () => {
    const isMobile = UseViewPort() < 992;
    const accesos = [
        {
            title: 'Portal Transportes',
            url: '/#portal-transportes',
            image: logoTransporte,
            bgAlias: 'transporte'
        },
        {
            title: 'Portal Agencia',
            url: '/#portal-agencia',
            image: logoAgencia,
            bgAlias: 'agencia-glr'
        },
        {
            title: 'Portal Almacén',
            url: '/#portal-almacen',
            image: logoFreight,
            bgAlias: 'freight'
        }
    ]
    return <div>
        {isMobile ? <AccesoMobil accesos={accesos}/> : <AccesoDesktop accesos={accesos}/>}
    </div>
}

export default Acceso;