import i18n from "i18next";
import React, {useState} from 'react';
import {Col, Container, Dropdown, Nav, Navbar, Row, Tab, Tabs} from "react-bootstrap";

import logo from '../../images/rodriguez-logo.png';
import "./header/NavPills.scss";

import "./Header.scss";
import HeaderContacto from "./header/HeaderContacto";
import {UseViewPort} from "../../hooks/useViewPort";

const Header = (props) => {
    const isDesktop = UseViewPort() > 992;

    const [toggle, setToggle] = useState(false);
    const handleClickChangeLang = (lang) => {
        console.log(lang);
    }

    const toggleMenu = () => {
        setToggle(!toggle);
    }

    return (<header>
        <Navbar bg={`grayish ${props.bg ?? ''}`} variant="dark" expand="lg" className={props.scroll ? 'scroll' : ''}>
            <Container>
                <Navbar.Brand href={`${process.env.PUBLIC_URL}/`}>
                    <img src={logo} alt="Grupo Logístico Rodríguez"
                         className="py-2 px-3 py-sm-3 px-sm-4 py-md-3 px-md-5  bg-white"/>
                    <h1 className={"d-none"}>Grupo Logístico Rodríguez</h1>
                </Navbar.Brand>
                <Navbar.Collapse id="rodriguez-navbar"
                                 className={"justify-content-end flex-column  flex-wrap navbar-collapse collapse show"}>
                    <Nav className="me-auto">
                        <Nav.Link href={`${process.env.PUBLIC_URL}/`} key={"inicio"}>Inicio</Nav.Link>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/contacto`} key={"contacto"}>Contacto</Nav.Link>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/agencia/cotizacion`} key={"cotizar"}>Cotizar</Nav.Link>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/acceso`} key={"acceso-glr"}>
                            <div className={"acceso-glr"}>Acceso GLR</div>
                        </Nav.Link>
                        {process.env.REACT_APP_ENABLE_LANG === true && <React.Fragment>
                            <div className="navbar-nav ml-auto nav-link">
                                <Dropdown>
                                    <Dropdown.Toggle variant="clear" className={"nav-item"}>
                                        <i className={`language ${i18n.language === "en" ? 'lang-en' : 'lang-es'}`}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            className={`dropdown-item ${i18n.language === 'en' ? 'active' : ''}`}
                                            onClick={() => handleClickChangeLang("en")}>English</Dropdown.Item>
                                        <Dropdown.Item
                                            className={`dropdown-item ${i18n.language === 'es' ? 'active' : ''}`}
                                            onClick={() => handleClickChangeLang("es")}>Español</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </React.Fragment>}


                        <div className="navbar-nav ml-auto second-menu">
                            <button className={"nav-extra"} onClick={() => toggleMenu()}/>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Container fluid
                   className={`px-0 px-md-2 menu-toggle ${(toggle) ? 'toggled' : ''} ${props.scroll ? 'scroll' : ''} ${props.exchange ? 'main-toggled' : ''}`}>
            <Container className={"p-0 py-md-4"}>
                <Tabs
                    variant="pills"
                    defaultActiveKey="servicios"
                    transition={false}
                    className="mb-md-3"
                >
                    <Tab eventKey="servicios" title="Servicios">
                        <Row className={"px-3 px-md-0 mt-md-4"}>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>Grupo Logístico Rodríguez</h6>
                                    <ul>
                                        <li>
                                            <a href={"/grupo"}>Grupo</a>
                                        </li>
                                        <li>
                                            <a href={"/historia"}>Historia</a>
                                        </li>
                                        <li>
                                            <a href={"/servicios"}>Servicios</a>
                                        </li>
                                        <li>
                                            <a href={"/clientes"}>Clientes</a>
                                        </li>
                                        <li>
                                            <a href={"/agencia/cotizacion"}>
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>R&R International Transport</h6>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href={"#servicios-logisticos"}>*/}
                                        {/*        Servicios logísticos*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href={"/contacto"}>
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>R&R International Freight
                                        <span className={"d-block"}>Forwarding</span></h6>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href={"#servicios-logisticos"}>*/}
                                        {/*        Servicios logísticos*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href={"/contacto"}>
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6><a href={"/acceso"} className={"text-reset"}>Acceso GLR</a></h6>
                                    <ul>
                                        <li>
                                            <a href={"#portal-servicios-digitales"}>
                                                Portal de servicios digitales
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"#wms"}>
                                                Warehouse management system
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"#portal-seguimiento-transporte"}>
                                                Portal de seguimiento transporte
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="cotizacion" title="Empleo GLR">
                        <Row className={"px-3 px-md-0 mt-md-4"}>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>Grupo Logístico Rodríguez</h6>
                                    <ul>
                                        <li>
                                            <a href={"#vacantes"}>
                                                Información de vacantes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>R&R International Transport</h6>
                                    <ul>
                                        <li>
                                            <a href={"#vacantes"}>
                                                Información de vacantes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>R&R International Freight
                                        <span className={"d-block"}>Forwarding</span></h6>
                                    <ul>
                                        <li>
                                            <a href={"#vacantes"}>
                                                Información de vacantes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="contacto" title="Contacto">
                        <HeaderContacto/>
                    </Tab>
                </Tabs>
            </Container>
        </Container>
    </header>);
};

export default Header;