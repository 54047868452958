import MainHeader from "../../UI/MainHeader";
import MainImage from '../../../images/grupo/grupo-main.png';
import QuienesSomos from "./QuienesSomos";
import OfertaSoluciones from "./OfertaSoluciones";
import Almacen from "./Almacen";
import Operaciones from "./Operaciones";
import Sucursales from "./Sucursales";
import TecnologiaExclusiva from "./TecnologiaExclusiva";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";

const Grupo = () => {
    return(<div>
        <MainHeader image={MainImage} activeButton={true}/>
        <QuienesSomos/>
        <OfertaSoluciones/>
        <Sucursales/>
        <TecnologiaExclusiva/>
        <Operaciones/>
        <Almacen/>
        <PluginChat/>
        <Footer/>
    </div>);
}

export default Grupo;