import Header from "../../UI/Header";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import {Col, Container, Row} from "react-bootstrap";
import {UseViewPort} from "../../../hooks/useViewPort";
import "./Cotizacion.scss";
import {useEffect, useRef, useState} from "react";
import CotizacionOperacion from "./CotizacionOperacion";

const CotizacionContacto = ({type, children, active, setActive, opcionMenosImg, opcionMasImg}) => {
    const isDesktop = UseViewPort() > 992;
    const cotizacionRef = useRef();
    const [height, setHeight] = useState(100);

    useEffect(() => {
        setHeight(cotizacionRef.current.getBoundingClientRect().width);
    },[cotizacionRef]);

    return <div>
        <Header bg={"bg-dark-gray"}/>
        <div className={"main cotizacion"}>
            <div className={"cotizacion-descripcion"}>
                <Container className={"pt-4 pt-md-5"}>
                    <Row className={"align-items-center d-flex justify-content-between"}>
                        <Col xs lg={6} className={"text-justify pr-5"}>
                            <h2 className={"font-weight-light mb-3"}>Cotiza su servicio de {type}
                                <span className={"d-block font-weight-bold"}>- Completa este formato </span></h2>
                            <p>
                                Si eres una empresa que maneja más o menos 10 operaciones al mes o tienes un
                                proyecto con operación importante en puerta, tenemos la solución.</p>
                            <p>Déjanos los datos solicitados para mandarte a tu correo electronico tu cotización o
                                bien uno de nuestros ejecutivos se ponrá en contacto contigo para atenderte
                                personalmente.
                            </p>
                        </Col>
                        {isDesktop && <Col xs={5}>
                            <Row>
                                <Col xs className={"d-none d-lg-block divisor-vertical-gray"}/>

                                <Col xs className={"text-left"}>
                                    <Row>
                                        <Col xs={12} className={"mt-2 icon at-email mb-4"}>
                                            <div className={"text-left"}>
                                                <strong className={"d-block"}>Cotíza vía E-mail</strong>
                                                <a href={"mailto:negocios@aar.com.mx"}
                                                   className={"text-decoration-none"}>negocios@aar.com.mx</a>
                                            </div>
                                        </Col>
                                        <Col xs={12} className={"mt-2 icon phone-call mb-4"}>
                                            <div className={"text-left"}>
                                                <strong className={"d-block"}>Llámanos | Cotizaciones</strong>
                                                <a href={"tel:526646831022"} className={"text-decoration-none"}>+52
                                                    (664) 683 1022</a>
                                            </div>
                                        </Col>
                                        <Col xs={12} className={"mb-3 icon home-location mx-auto max-width"}>
                                            <div className={"text-left"}>
                                                <strong className={"d-block"}>Horario- Sucursales</strong>
                                                Lunes - Viernes&nbsp;&nbsp; 8 am - 5:30 pm
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>}
                    </Row>
                    <Row>
                        <p>Selecciona la opción indicada:</p>
                    </Row>
                </Container>
            </div>

            <Container className={"pt-3 pt-md-2"}>
                <Row>
                    <Col xs={12} sm={10} lg={6} className={"mx-auto text-center"}>
                        <Row>
                            <Col xs={6} ref={cotizacionRef}>
                                <CotizacionOperacion active={active === "menor" ? 'active' : null} height={(height - 20)} backgroundImage={opcionMenosImg} type={"menos"} onClick={() => (setActive("menor"))}/>
                            </Col>
                            <Col xs={6}>
                                <CotizacionOperacion active={active === "mayor" ? 'active' : null} height={(height - 20)} backgroundImage={opcionMasImg} type={"mas"} onClick={() => (setActive("mayor"))}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container>
                {children}
            </Container>
        </div>
        <PluginChat/>
        <Footer/>
    </div>
}

export default CotizacionContacto;