import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import "./QuienesSomos.scss";
import quiensSomosImg from "./../../../images/grupo/grupo-mujer.png";
import {UseViewPort} from "../../../hooks/useViewPort";

const QuienesSomos = () => {
    const isMobile = UseViewPort() < 992;

    const TextComponent = () => {
        return <React.Fragment>
            <Col lg={6} xl={6} className={"p-4 py-md-5"}>
                <h2>¿Quiénes somos?</h2>
                <p>Nos conformamos como un Grupo Logístico a través de nuestras tres unidades de negocio: Agencia Aduanal, Transporte y Almacén. </p>
                <p>Nuestros  más de 80 años de experiencia nos permiten brindar atención especializada y personalizada con un respaldo jurídico inigualable a las principales industrias.</p>
                <h2>Filosofía</h2>
                <p>En nuestra filosofía de servicio consideramos que somos socios de nuestros clientes, al ser una extensión importante en sus actividades empresariales, logrando consolidar relaciones de largo plazo con las cuales realizamos anualmente un alto volumen de operaciones que nos posicionan entre las principales agencias aduanales.</p>
            </Col>
        </React.Fragment>;
    }

    const ImageComponent = () => {
        return <React.Fragment>
            <Col lg xl={5} className={"position-relative strip text-center"}>
                <img src={quiensSomosImg} alt={"Quienes somos"} className={"w-100"}/>
            </Col>
        </React.Fragment>;
    }

    return <div id={"quienes-somos"}>
        { !isMobile && <div className={"my-md-4"}>
            <Container>
                <Row>
                    <Col>
                        <Row className={"justify-content-between"}>
                            <TextComponent/>
                            <ImageComponent/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div> }
        { isMobile && <div className={"my-md-4"}>
            <Container>
                <Row>
                    <Col>
                        <Row className={"justify-content-between"}>
                            <TextComponent/>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={"px-0"}>
                <ImageComponent/>
            </Container>
        </div> }
    </div>
}

export default QuienesSomos;