import {Col, Container, Dropdown, Nav, Navbar, Row, Tab, Tabs} from "react-bootstrap";
import "./HeaderContacto.scss";

const HeaderContacto = () => {
    return <Row className={"px-3 px-md-0 mt-md-4"}>
    <Col xs={12} md  className={"menu-list header-contacto"}>
            <div>
                <h6>Grupo Logístico Rodríguez</h6>
                <ul>
                    <li>
                        <h6>Tijuana</h6>
                        <div>
                            Tel: +52 (664) 683 1022 / 23<br/>
                            Blvd. 3ra Oeste No. 17500-C<br/>
                            Fracc. Garita de Otay,<br/>
                            C.P: 22430 Tijuana, B.C.
                        </div>
                    </li>
                    <li>
                        <h6>Ensenada</h6>
                        <div>
                            Tel: +52 (646) 156 5392<br/>
                            Tel: +52 (646) 175 7206<br/>
                            Virgilio Uribe 649 Local 11<br/>
                            Plaza Delfines<br/>
                            C.P. 22800, Ensenada, B.C.
                        </div>
                    </li>
                </ul>
            </div>
        </Col>
        <Col xs={12} md className={"menu-list"}>
            <div>
                <h6>R&R International Transport</h6>
                <ul>
                    <li>
                        <h6>Tijuana</h6>
                        <div>
                            Tel: +52 (664) 683 1022 / 23<br/>
                            Blvd. 3ra Oeste No. 17500-C<br/>
                            Fracc. Garita de Otay,<br/>
                            C.P: 22430 Tijuana, B.C.
                        </div>
                    </li>
                </ul>
            </div>
        </Col>
        <Col xs={12} md  className={"menu-list"}>
            <div>
                <h6>R&R International Freight
                    <span className={"d-block"}>Forwarding</span></h6>
                <ul>
                    <li>
                        <h6>San Diego</h6>
                        <div>
                            2450 Siempre Viva CT.<br/>
                            San Diego CA 92154<br/>
                            Tel: +1 (619) 661 0027<br/>
                            Fax: +1 (619) 661 0044
                        </div>
                    </li>
                </ul>
            </div>
        </Col>
    </Row>
}

export default HeaderContacto;