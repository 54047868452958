import {Col, Container, Row} from "react-bootstrap";
import "./Servicios.scss";
import service4 from '../../images/servicios/servicio-1.jpg';
import service2 from '../../images/servicios/servicio-2.jpg';
import service3 from '../../images/servicios/servicio-3.jpg';
import service1 from '../../images/servicios/servicio-1.png';
import serviciosMain from '../../images/servicios/servicios-main.png';

import MainHeader from "../UI/MainHeader";
import PluginChat from "../UI/PluginChat";
import Footer from "../UI/Footer";

const Servicios = () => {
    return <div>
        <MainHeader image={serviciosMain}/>
        <Container fluid className={"main servicios"}>
            <Container className={"py-3 py-md-5"}>
                <Row className={"pb-3 pb-md-5"}>
                    <Col>
                        <h2 className={"mb-3"}>Servicios</h2>
                        <p className={"text-justify"}>Contamos con diferentes servicios especializados para tu
                            empresa.<strong>Grupo Logístico Rodríguez </strong>es la solución a tu cadena de suministro,
                            nuestra experiencia, certificaciones y socios comerciales nos respaldan. Conformados cor más
                            de 200 colaboradores dedicados a mover y cuidar tu mercancía en el menor tiempo
                            posible. <strong>Tus Socios en Comercio Exterior.</strong></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className={"pb-3 pb-lg-5  service-info justify-content-between parent-hover"}
                             onClick={() => console.log('redirect...')}>
                            <Col xs={12} md lg={4} className={"description"}>
                                <h3 data-aos="fade-up">Agencia Aduanal Rodríguez</h3>
                                <p>Nuestra prioridad es atender los requerimientos de tu empresa, con rapidez,
                                    eficiencia, cumplimiento y seguridad en todas las operaciones de comercio exterior y
                                    logística. </p>
                                <button className={"btn-filled btn-bg-lightgray"}>Más Información</button>
                                <hr className={"d-none d-md-block hr-yellow"}/>
                            </Col>
                            <Col xs={11} md={6} lg={7} className={"pb-4 pb-lg-0 img-container arrow-left"}>
                                <img src={service1} className={"img-fluid rounded"} alt={"Agencia Aduanal Rodríguez"}/>
                            </Col>
                        </Row>
                        <Row className={"pb-3 pb-lg-5 service-info justify-content-between parent-hover"}
                             onClick={() => console.log('redirect...')}>
                            <Col xs={12} md lg={4} className={"description"}>
                                <h3 data-aos="fade-up"><span className={"d-md-block"}>R&R International</span>
                                    Transport</h3>
                                <p>Proveemos soluciones de logística acordes con las necesidades de tu empresa, con una
                                    amplia cobertura de servicio de transporte en la zona binacional.</p>
                                <button className={"btn-filled btn-bg-lightgray"}>Más Información</button>
                                <hr className={"d-none d-md-block  hr-yellow"}/>
                            </Col>
                            <Col xs={11} md={6} lg={7} className={"pb-4 pb-lg-0 img-container arrow-right"}>
                                <img src={service2} className={"img-fluid rounded"}
                                     alt={"R&R International Transport"}/>
                            </Col>
                        </Row>
                        <Row className={"pb-3 pb-lg-5 service-info justify-content-between parent-hover"}
                             onClick={() => console.log('redirect...')}>
                            <Col xs={12} md lg={4} className={"description"}>
                                <h3 data-aos="fade-up"><span className={"d-md-block"}>R&R International </span>
                                    Freight Forwarding</h3>
                                <p>Proveemos soluciones de logística acordes con las necesidades de tu empresa, con una
                                    amplia cobertura de servicio de transporte en la zona binacional.</p>
                                <button className={"btn-filled btn-bg-lightgray"}>Más Información</button>
                                <hr className={"d-none d-md-block hr-yellow"}/>
                            </Col>
                            <Col xs={11} md={6} lg={7} className={"pb-4 pb-lg-0 img-container arrow-left"}>
                                <img src={service3} className={"img-fluid rounded"}
                                     alt={"R&R International Freight Forwarding, Inc."}/>
                            </Col>
                        </Row>
                        <Row className={"pb-3 service-info justify-content-between parent-hover"}
                             onClick={() => console.log('redirect...')}>
                            <Col xs={12} md lg={4} className={"description"}>
                                <h3 className={"aos-animate"} data-aos="fade-in" data-aos-duration="8000">Servicio
                                    Integral</h3>
                                <p>Te brindamos el servicio completo para que tu empresa este completa sin
                                    preocupaciones ofreciéndote los servicios completos integrales. </p>
                                <button className={"btn-filled btn-bg-lightgray"}>Más Información</button>
                            </Col>
                            <Col xs={11} md={6} lg={7} className={"pb-4 pb-lg-0 img-container arrow-right"}>
                                <img src={service4} className={"d-block img-fluid rounded"} alt={"Servicio Integral"}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <PluginChat/>
        </Container>
        <Footer/>
    </div>
}

export default Servicios;