import {Col, Container, Row} from "react-bootstrap";
import "./OfertaSoluciones.scss";
import logoGLR from "./../../../images/grupo/logo-glr.png";
import logoTransport from "./../../../images/grupo/logo-transport.png";
import logoFreight from "./../../../images/grupo/logo-freight.png";
import ventajaCobertura from "./../../../images/grupo/ventajas-cobertura.png";
import ventajaServicio from "./../../../images/grupo/ventajas-servicio.png";
import ventajaTecnologia from "./../../../images/grupo/ventajas-tecnologia.png";
import YoutubeEmbed from "../../UI/YoutubeEmbed";

const OfertaSoluciones = () => {
    return <Container className={"p-4 py-md-0"}>
        <Row>
            <Col>
                <h2>¿Qué soluciones ofrecemos?</h2>
                <p>Nuestra infraestructura tiene cobertura directa en Tijuana, Ensenada y San Diego, facilitando el trayecto internacional de mercancías.</p>
            </Col>
        </Row>
        <Row className={"text-center"}>
            <Col xs={4} className={"grupo-solucion"}>
                <img src={logoGLR}/>
                <p>
                    <span className={"d-md-block"}>Servicio integral, aduanal, </span>
                    transporte terrestre y almacén
                </p>
            </Col>
            <Col xs={4}  className={"grupo-solucion"}>
                <img src={logoTransport}/>
                <p>
                    <span className={"d-md-block"}>Transporte de mercancías </span>
                    a nivel binacional</p>
            </Col>
            <Col xs={4}  className={"grupo-solucion"}>
                <img src={logoFreight}/>
                <p><span className={"d-md-block"}>Servicio de almacenamiento de </span>
                <span className={"d-md-block"}>mercancías, transbordos y revisión </span> de mercancías</p>
            </Col>
        </Row>
        <Row className={"text-center"}>
            <Col lg={11} className={"mx-auto mt-5"}>
                <Row>
                    <h2>Ventajas competitivas</h2>
                </Row>
                <Row>
                    <Col xs={12} md={4} className={"ventaja-competitiva"}>
                        <img src={ventajaCobertura} className={"w-100"}/>
                        <p>Cobertura Internacional</p>
                    </Col>
                    <Col xs={12} md={4}  className={"ventaja-competitiva"}>
                        <img src={ventajaServicio} className={"w-100"}/>
                        <p><span className={"d-block"}>Experiencia de servicio </span> personalizado</p>
                    </Col>
                    <Col xs={12} md={4}  className={"ventaja-competitiva"}>
                        <img src={ventajaTecnologia} className={"w-100"}/>
                        <p><span className={"d-block"}>Tecnología y comunicación </span>
                            de vanguardia</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} lg={6} className={"py-3 py-md-5 mx-auto"}>
                        <YoutubeEmbed embedId={"i_h_qVdH6UQ"}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
}

export default OfertaSoluciones;