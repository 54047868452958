import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import "./Contacto.scss";
import MainImage from "../../images/contacto/contacto-main.png";
import logo from "../../images/contacto/rodriguez.png";

import MainHeader from "../UI/MainHeader";
import PluginChat from "../UI/PluginChat";
import {Grid, LoadingButton} from "@mui/material";

import theme from "./../../theme";
import {ThemeProvider} from "@emotion/react";
import {useEffect, useRef, useState} from "react";
import CustomForm from "../UI/CustomForm";
import {contactSchema} from "./../../validations/ContactValidation";
import Footer from "../UI/Footer";
import {postContact} from "../../utils/forms";

const Contacto = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [fieldsValues, setFieldsValues] = useState({});
    const contactRef = useRef();
    const [status, setStatus] = useState(false);
    const [height, setHeight] = useState(100);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setHeight(contactRef.current.getBoundingClientRect().height);
    }, [contactRef]);

    const fields = [
        {
            type: "text",
            name: "firstname",
            required: true,
            rowSize: 6,
            placeholder: "Nombre",
        },
        {
            type: "text",
            name: "lastname",
            required: true,
            rowSize: 6,
            placeholder: "Apellido",
        },
        {
            type: "email",
            name: "email",
            required: true,
            rowSize: 6,
            placeholder: "Correo Electrónico",
        },
        {
            type: "phone",
            name: "phone",
            required: true,
            rowSize: 6,
            placeholder: "Teléfono",
        },
        {
            type: "select",
            name: "category",
            required: true,
            rowSize: 6,
            placeholder: "Seleccione una categoría de mensaje",
            validator: "Categoría",
            options: ["Consulta / Comentarios", "Presupuesto", "Seguimiento"]
        },
        {
            type: "textarea",
            rows: 4,
            name: "notes",
            required: true,
            rowSize: 12,
            placeholder: "¿Cómo podemos ayudarte?",
            validator: "Mensaje"
        },
        {
            type: "checkbox",
            name: "subscribe",
            required: true,
            rowSize: 11,
            placeholder:
                "Sí, me gustaría recibir noticias, comunicados y actualizaciones de Grupo Logístico Rodríguez. Puedo cancelar mi suscripción en cualquier momento. En nuestra Política de privacidad encontrarás más información acerca de cómo utilizamos los datos personales.",
        },
    ];

    const validateData = async() => {
        try {
            setErrorMsg("");
            await contactSchema.validate(fieldsValues, { abortEarly: false });
            return true;
        } catch (e) {
            let messages = e.errors;

            if(!messages.length) return true;
            let populateErrors = [];

            fields.map((field) => {
                const match = messages.find((element, index) => {
                    let containText = field.validator ?? field.placeholder;
                    if (element.includes(containText)) {
                        return messages[index];
                    }
                    return false;
                });

                if(typeof match === 'string'){
                    populateErrors[field.name] = match;
                }
                return true;
            });
            setErrors(populateErrors);
            return false;
        }
    }

    const sendForm = () => {
        setLoading(true);
        return validateData().then( async (response) => {
            if(response){
                fieldsValues.formname = 'Contacto Agencia';
                fieldsValues.formtitle = '';

                await postContact(fieldsValues).then((result) => {
                    setStatus(true);
                }).catch( () => {
                    setErrorMsg('Hubo un error, favor intente nuevamente.');
                }).finally(() => {
                    setLoading(false);
                })
            }else{
                setLoading(false);
            }

        });
    };

    return (
        <div className={"contacto"}>
            <MainHeader
                image={MainImage}
                alignRightBottom={true}
                hideButton={true}
                header={
                    <h4 className={"mt-2"}>
                        <span className={"d-block text-medium"}>
                            Ponte en contacto con nosotros
                        </span>
                        <span className={"d-block"}>Nos encanta atenderte</span>
                    </h4>
                }
            />
            <Container fluid ref={contactRef}>
                {status ? (
                    <Container
                        className={"py-3 py-md-5 text-center form-response"}
                        style={{height: height}}
                    >
                        <Row>
                            <Col className={"text-center"} lg={11}>
                                <Row>
                                    <Col xs={7} className={"mx-auto"}>
                                        <img
                                            src={logo}
                                            alt={"Rodriguez"}
                                            className={"img-fluid mb-5"}
                                        />
                                    </Col>
                                </Row>
                                <h4 className={"mb-4"}>
                                    ¡Tu solicitud ha sido enviada con éxito!
                                </h4>
                                <p className={"mx-auto d-block text-center"}>
                                    Nuestro equipo de atención revisará la
                                    información y se comunicará contigo a la
                                    brevedad.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container className={"py-3 py-md-5"}>
                        <Row className={"pb-3 pb-md-5"}>
                            <Col lg={10} className={"mx-auto"}>
                                <h2 className={"mb-3"}>
                                    <span className={"d-md-block"}>
                                        Completa esta información y nos
                                        pondremos en{" "}
                                    </span>
                                    contacto contigo lo antes posible
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={10} className={"mx-auto"}>
                                <ThemeProvider theme={theme}>
                                    <Container>
                                        <Grid container spacing={2} align={"center"} >
                                            <CustomForm fieldsValues={fieldsValues} fields={fields}  setFieldsValues={setFieldsValues} errors={errors} setErrors={setErrors} formError={errorMsg} setFormError={setErrorMsg}/>
                                            <Grid item xs={12} align={"left"}>
                                                <Button key="contact-btn" className={"btn btn-enviar mb-4 mb-md-0 border-none"} onClick={sendForm}>
                                                    {loading ? 'Enviando...' : 'Envíe su pregunta'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </ThemeProvider>
                            </Col>
                        </Row>
                    </Container>
                )}
                <PluginChat/>
            </Container>
            <Footer/>
        </div>
    );
};

export default Contacto;
