import {Col, Container, Row} from "react-bootstrap";
import "./TecnologiaExclusiva.scss";
import smartPhones from "./../../../images/grupo/cellphones.png";

const TecnologiaExclusiva = () => {
    return <div id={"tecnologia-exclusiva"}>
        <Container className={"py-2 py-md-5 position-relative"}>
            <Row className={"d-flex flex-row-reverse"}>
                <Col className={"py-3 px-4 p-md-2"}>
                    <Row>
                        <Col>
                            <h2>Tecnología exclusiva</h2>
                            <p> Tenemos un firme compromiso con tu negocio, el cual manifestamos desarrollando para ti un conjunto de aplicaciones con tecnología innovadora que generan información en tiempo real para aportar a la toma oportuna de decisiones.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={10} md={9} lg={8} className={"mx-auto mt-3"}>
                            <img src={smartPhones} className={"img-fluid"}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}

export default TecnologiaExclusiva;