import {Col, Container, Row} from "react-bootstrap";
import Slider from "react-slick";

import "./Nosotros.scss";
import nosotrosMain from '../../images/nosotros/nosotros-main.png';
import rigoRodriguez from '../../images/nosotros/rigordgz.png';
import tijuana from '../../images/nosotros/tijuana.png';
import valueOne from '../../images/nosotros/time.png';
import valueThree from '../../images/nosotros/lock.png';
import valueTwo from '../../images/nosotros/hands.png';
import valueFour from '../../images/nosotros/cloud.png';
import valueFive from '../../images/nosotros/certs.png';

import usClients from '../../images/nosotros/nosotros-clientes.png';
import usGroup from '../../images/nosotros/nosotros-grupo.png';
import usHistory from '../../images/nosotros/nosotros-historia.png';
import nosotrosTestimonial from '../../images/nosotros/testmonial-testimonial.png';
import nosotrosTestimonialMobile from '../../images/nosotros/testmonial-testimonial-circle.png';

import MainHeader from "../UI/MainHeader";
import PluginChat from "../UI/PluginChat";
import {useState, useRef} from 'react';
import {UseViewPort} from "../../hooks/useViewPort";
import Footer from "../UI/Footer";

const Nosotros = () => {
    const isDesktop = UseViewPort() > 992;

    const slides = [
        {
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>La integridad, el Compromiso y el Respeto</strong>, son los colores que durante generaciones han conservado en nuestra empresa, siendo estos escenciales para forjar relaciones perdurables con nuestros clientes.",
                name: "Rigoberto Rodríguez / Director General"
            }
        },{
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>Lorem Ipsum</strong>, Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn",
                name: "Juan Rodríguez / Director General"
            }
        },{
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>Lorem Ipsum</strong>, Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn",
                name: "Mario Rodríguez / Director General"
            }
        },{
            image: <img src={rigoRodriguez} alt={"Rigoberto Rodriguez"}  className={"w-100"}/>,
            description: {
                paragraph: "<strong>Lorem Ipsum</strong>, Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn Lorem Ipsumn",
                name: "José Rodríguez / Director General"
            }
        }
    ];

    const [description, setDescription] = useState(slides[0].description);

    const  slickRef = useRef();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <div className={"nosotros"}>
        <MainHeader image={nosotrosMain}/>
        <Container fluid className={"main px-0"}>
            <Container className={"py-3 p-md-5"}>
                <Row>
                    <Col>
                        <p className={"text-center mt-2"}>
                            <strong>Más de 80 años evolucionando la calidad en el comercio exterior.</strong>
                        </p>
                    </Col>
                </Row>
                <Row className={`${isDesktop ? 'mb-4 my-md-2' : ''} social-networks hover-color text-center`}>
                    <Col xs={12}>
                        <ul className={"mt-0 pb-1"}>
                            <li><a target="_blank" href={`${process.env.REACT_APP_LINK_LINKEDIN}`} title="LinkedIn" rel="nofollow"
                                   className={"linkedin gray-scale"}>LinkedIn</a></li>
                            <li><a target="_blank"  href={`${process.env.REACT_APP_LINK_FB}`} title="Facebook" rel="nofollow"
                                   className={"facebook gray-scale"}>Facebook</a></li>
                            <li><a target="_blank"  href={`${process.env.REACT_APP_LINK_YOUTUBE}`} title="YouTube" rel="nofollow"
                                   className={"youtube gray-scale"}>YouTube</a></li>
                            <li><a target="_blank"  href={`${process.env.REACT_APP_LINK_TWITTER}`} title="Twitter" rel="nofollow"
                                   className={"twitter gray-scale"}>Twitter</a></li>

                        </ul>
                    </Col>
                </Row>
            </Container>

            <div className={"testimonials py-3"}>
                { isDesktop && <Row className={"py-2 px-4 p-lg-5"}>
                    <Col className={"m-auto col-lg-9 col-xl-7 col-xxl-8 col-md-12"}>
                        <Row className={"justify-content-center justify-content-lg-between d-flex flex-md-row-reverse py-3 py-md-5"}>
                            <Col xs={8} lg={5} className={"pb-5 pb-lg-0 px-lg-5"}>
                                <img src={nosotrosTestimonial} className={"img-fluid"}/>
                            </Col>
                            <Col lg={7} className={"testimonial-text"}>
                                <div>
                                    <p dangerouslySetInnerHTML={{ __html: `"${description.paragraph}"` }}/>
                                    <p className={"mb-0"}><strong>{description.name}</strong></p>
                                    <p>Grupo Logístico Rodríguez</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row> }

                { !isDesktop && <Row className={"px-4 py-2 col-10 mx-auto"}>
                    <p dangerouslySetInnerHTML={{ __html: `"${description.paragraph}"` }}/>
                    <p className={"mb-0 text-center"}><strong>{description.name}</strong></p>
                    <p className={"text-center"}>Grupo Logístico Rodríguez</p>

                    <Row>
                        <Col xs={7} sm={6} className={"mx-auto py-3"}>
                            <img src={nosotrosTestimonialMobile} className={"img-fluid"}/>
                        </Col>
                    </Row>
                </Row>}
            </div>
            <div className={"core-values"}>
                <Container className={"py-3 pt-md-5 pb-md-4"}>
                    <Row>
                        <Col>
                            <h2 className={"text-yellow"}>Cadena de Valor</h2>
                            <p>Hacemos la diferencia a nuestros clientes, distinguiendo y ofreciendo.</p>
                        </Col>
                    </Row>
                </Container>
                <div className={"icons"}>
                    <Container className={"py-5"}>
                        <Row>
                            <Col className={"mx-auto text-center"} xs={10} md={11} xl={10}>
                                <Row>
                                    <Col xs={4} sm={6} md>
                                        <div className={"icon-value"} style={{backgroundImage: `url(${valueOne})`}}/>
                                        <h6 className={"text-yellow transparency"}>
                                            <span className={"d-md-block"}>Excelente tiempo </span>
                                            de respuesta
                                        </h6>
                                    </Col>
                                    <Col xs className={"d-none d-md-block divisor-vertical-yellow transparency"}/>
                                    <Col xs={4} sm={6} md>
                                        <div className={"icon-value"} style={{backgroundImage: `url(${valueTwo})`}}/>
                                        <h6 className={"text-yellow transparency"}>
                                            <span className={"d-md-block"}>Atención </span>personalizada
                                        </h6>
                                    </Col>
                                    <Col xs className={"d-none d-md-block divisor-vertical-yellow transparency"}/>
                                    <Col xs={4} sm={6} md>
                                        <div className={"icon-value"} style={{backgroundImage: `url(${valueThree})`}}/>
                                        <h6 className={"text-yellow transparency"}>
                                            <span className={"d-md-block"}>Seguridad y gestión </span>
                                            de riesgo
                                        </h6>
                                    </Col>
                                    <Col xs className={"d-none d-md-block divisor-vertical-yellow transparency"}/>
                                    <Col xs={4} sm={6} md className={"mx-auto"}>
                                        <div className={"icon-value"} style={{backgroundImage: `url(${valueFour})`}}/>
                                        <h6 className={"text-yellow transparency"}>
                                            <span className={"d-md-block"}>Tecnología y Asesoría </span>
                                            especializada.
                                        </h6>
                                    </Col>
                                    <Col xs className={"d-none d-md-block divisor-vertical-yellow transparency"}/>
                                    <Col xs={4} sm={6} md className={"mx-auto"}>
                                        <div className={"icon-value my-3"} style={{backgroundImage: `url(${valueFive})`}}/>
                                        <h6 className={"text-yellow transparency"}>
                                            Certificaciones
                                        </h6>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Container className={"pt-5 py-md-5 about-more"}>
                <Row className={"text-center justify-content-around"}>
                    <Col md={3} className={"img-zoom mb-4 mb-md-0"}>
                        <div className={"img-container"}>
                            <img src={usHistory} className={"img-fluid"}/>
                        </div>
                        <h4>Historia</h4>
                    </Col>
                    <Col md={3} className={"img-zoom mb-4 mb-md-0"}>
                        <div className={"img-container"}>
                            <img src={usGroup} className={"img-fluid"}/>
                        </div>
                        <h4>Grupo</h4>
                    </Col>
                    <Col md={3} className={"img-zoom"}>
                        <div className={"img-container"}>
                            <img src={usClients} className={"img-fluid"}/>
                        </div>
                        <h4>Clientes</h4>
                    </Col>
                </Row>
            </Container>
            <Container className={"py-3 py-md-5 offices"}>
                <Row className={"mb-4"}>
                    <Col>
                        <h2>Oficinas en México y Estados Unidos.</h2>
                        <p><strong>En Grupo Logístico Rodríguez </strong>estamos disponibles las 24 horas de los 7 días de la semana para darte el servicio aduanal que te mereces. Nuestra presencia en México y Estados Unidos, nos permite atenderte de acuerdo con tus necesidades.</p>
                    </Col>
                </Row>


                <Row className={"my-2 text-center"}>
                    <Col xs={12} md={6} lg={4} className={"mx-auto"}>
                        <img src={tijuana} alt={"Tijuana"} className={"img-fluid mb-3 col-md-12 col-8 col-md-12"}/>
                        <h3 className={"mx-auto d-block text-center"}>Tijuana</h3>
                        <p className={"text-smaller text-center"}><span className={"d-block"}>Blvd. 3ra Oeste No. 17500-C</span>
                            Fracc. Garita de Otay, C.P: 22509 Tijuana, B.C.</p>
                        <button className={"btn-filled btn-small mx-auto d-block mb-5 mb-md-0"} onClick={() => {
                            window.open('https://goo.gl/maps/DP4ydiszecPc4Goe8', '_blank');
                        }}>Ver Mapa</button>
                    </Col>
                    <Col xs={12} md={6} lg={4}  className={"mx-auto"}>
                        <img src={tijuana} alt={"Tijuana"} className={"img-fluid mb-3 col-8 col-md-12"}/>
                        <h3 className={"mx-auto d-block text-center"}>Ensenada</h3>
                        <p className={"text-smaller text-center"}><span className={"d-block"}>Virgilio Uribe 649 Plaza Delfines</span>
                            Ensenada, B.C. México, C.P. 22870</p>
                        <button className={"btn-filled btn-small mx-auto d-block mb-5 mb-md-0"} onClick={() => {
                            window.open('https://goo.gl/maps/v9cTEFXPpThrC1rM6', '_blank');
                        }}>Ver Mapa</button>
                    </Col>
                    <Col xs={12} md={6} lg={4}  className={"mx-auto"}>
                        <img src={tijuana} alt={"Tijuana"} className={"img-fluid mb-3 col-8 col-md-12"}/>
                        <h3 className={"mx-auto d-block text-center"}>San Diego</h3>
                        <p className={"text-smaller text-center"}><span className={"d-block"}>2450 Siempre Viva Ct.</span>
                            San Diego, CA. 92154</p>
                        <button className={"btn-filled btn-small mx-auto d-block mb-5 mb-md-0"} onClick={() => {
                            window.open('https://goo.gl/maps/mA88y8YAgBCSnoPB6', '_blank');
                        }}>Ver Mapa</button>
                    </Col>
                </Row>
            </Container>
            <PluginChat/>
        </Container>
        <Footer/>
    </div>
}

export default Nosotros;