import {useState, useRef, useEffect} from "react";
import {IsViewportVisible} from "./../../../hooks/IsViewportVisible";
import "./Contador.scss";


const Contador = props => {
    // label of counter
    // number to increment to
    // duration of count in seconds
    const {number, duration, description } = props;

    // number displayed by component
    const [count, setCount] = useState("0")
    const [scroll, setScroll] = useState(false);
    const counterRef = useRef();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(IsViewportVisible(counterRef.current.getBoundingClientRect()));
        });
    }, [counterRef]);

    useEffect(() => {
        if(!scroll) return;
        let start = 0;
        // first three numbers from props
        const end = parseInt(number.substring(0,3))
        // if zero, return
        if (start === end) return;

        // find duration per increment
        let totalMilSecDur = parseInt(duration);
        let incrementTime = (totalMilSecDur / end) * 1000;

        // timer increments start counter
        // then updates count
        // ends if start reaches end
        let timer = setInterval(() => {
            start += 1;
            setCount(String(start) + number.substring(3))
            if (start === end) clearInterval(timer)
        }, incrementTime);

        // dependency array
    }, [number, duration, scroll]);

    var numberFormat = Intl.NumberFormat();

    return (
        <div className={"contador"} ref={counterRef}>
            <h3>
                <h6>{numberFormat.format(count)}</h6>
                <p>{description}</p>
            </h3>
        </div>
    );
}

export default Contador;