import { useEffect, useState } from "react";

const IsViewportVisible = bounding => {
    if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth) &&
        bounding.bottom <= (window.innerHeight - 35)
    ) {
        return true;
    } else {
        return false;
    }
};

export {IsViewportVisible};