import ContactStrip from "./ContactStrip";
import ContactStripMobile from "./ContactStripMobile";

import Header from "./Header";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {UseViewPort} from "./../../hooks/useViewPort";

const MainHeader = ({image, exchange, activeButton, header, alignRightBottom, hideButton, type}) => {
    const isMobile = UseViewPort() < 992;

    const [scroll, setScroll] = useState();
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 75);
        });
    }, []);

    return <div className={`main-header ${type}`} style={{backgroundImage: `url(${image})`}}>
        <Header scroll={scroll} exchange={exchange | false}/>
        <Container className={`text-container my-1 my-md-5 ${isMobile && 'text-center'}  ${exchange && isMobile ? '' : 'py-4'} header-container ${scroll ? 'scroll' : ''}`}>
            <Row className={alignRightBottom ? 'justify-content-end align-items-end': null}>
                <Col xs={12} xl={6}>
                    <Row className={"d-flex flex-column-reverse  flex-lg-column"}>
                        { !hideButton && <Col xs md={12} className={"d-flex align-items-center align-items-lg-start"}>
                            <a className={`btn-filled btn-no-arrow mt-2 mt-md-3 mx-auto mx-lg-0 ${activeButton ? 'always-active' : null}`} href={`${process.env.PUBLIC_URL}/servicios`}>Solicita
                                Asesoría</a>
                        </Col>}
                        <Col xs md={12}>
                            {
                                header ?? <h4 className={"mt-2"}>
                                    <span className={"d-block"}>Nuestro compromiso:</span>
                                    <span className={"d-block"}>la excelencia de nuestro</span>
                                    <span className={"d-block"}>servicio.</span>
                                </h4>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        {isMobile ? <ContactStripMobile exchange={exchange | false}/> : <ContactStrip exchange={exchange | false}/>}
    </div>;
}

export default MainHeader;